// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import SwiperSinglePageRecommend from './components/SwiperSinglePageRecommend';

$(() => {
  if ($('#user_recommend_spot').length) {
    $.ajax({
      url: '/user_recommend_spot',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
    });
  }

  if ($('#item_recommend_spot').length) {
    $.ajax({
      url: '/item_recommend_spot',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      success: function () {
        const spotRecommendSwiperElm = document.querySelectorAll("[data-role='spot-recommend-swiper']");
        if (spotRecommendSwiperElm.length) {
          spotRecommendSwiperElm.forEach((elm) => {
            // eslint-disable-next-line no-new
            new SwiperSinglePageRecommend({ elm });
          });
        }
      },
    });
  }

  if ($('#item_recommend_event').length) {
    $.ajax({
      url: '/item_recommend_event',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      success: function () {
        const spotRecommendSwiperElm = document.querySelectorAll("[data-role='spot-recommend-swiper']");
        if (spotRecommendSwiperElm.length) {
          spotRecommendSwiperElm.forEach((elm) => {
            // eslint-disable-next-line no-new
            new SwiperSinglePageRecommend({ elm });
          });
        }
      },
    });
  }

  if ($('#item_recommend_feature').length) {
    $.ajax({
      url: '/item_recommend_feature',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      success: function () {
        const spotRecommendSwiperElm = document.querySelectorAll("[data-role='spot-recommend-swiper']");
        if (spotRecommendSwiperElm.length) {
          spotRecommendSwiperElm.forEach((elm) => {
            // eslint-disable-next-line no-new
            new SwiperSinglePageRecommend({ elm });
          });
        }
      },
    });
  }

  if ($('#item_recommend_course').length) {
    $.ajax({
      url: '/item_recommend_course',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      type: 'GET',
      timeout: 5000,
      success: function () {
        const spotRecommendSwiperElm = document.querySelectorAll("[data-role='spot-recommend-swiper']");
        if (spotRecommendSwiperElm.length) {
          spotRecommendSwiperElm.forEach((elm) => {
            // eslint-disable-next-line no-new
            new SwiperSinglePageRecommend({ elm });
          });
        }
      },
    });
  }
});
