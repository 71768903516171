class Content {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
    this.id = this.elm.dataset.contentId;
  }

  render() {
    if (this.app.state.activeContentId === this.id)
      this.elm.classList.remove(this.app.state.hideClassName);

    if (this.app.state.activeContentId !== this.id)
      this.elm.classList.add(this.app.state.hideClassName);
  }
}

export default Content;
