// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
// import * as ActiveStorage from '@rails/activestorage';
// import '../channels';
import 'controllers';
import '../japan_concierge/i18n/en';
import '../japan_concierge/i18n/fr';
import '../japan_concierge/i18n/id';
import '../japan_concierge/i18n/ja';
import '../japan_concierge/i18n/ko';
import '../japan_concierge/i18n/th';
import '../japan_concierge/i18n/vi';
import '../japan_concierge/i18n/zh-CN';
import '../japan_concierge/i18n/zh-TW';
import '../sprinkles';
import './server_rendering';

Rails.start();
// ActiveStorage.start();
