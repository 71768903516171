import Util from './Util';

export default class Form {
  constructor(args = {}) {
    this.store = args.store;
    this.node = document.querySelector('[data-role="jalan-form"]');
    // inputs
    this.areaInputs = document.querySelectorAll('input[name="lrgCd"]');
    this.catInputs = document.querySelectorAll('input[name="category"]');
    this.commitInputs = document.querySelectorAll('input[name="kdCond"]');
  }

  noCheckAllInputs(nodes) {
    Array.prototype.slice.call(nodes, 0).forEach((input) => {
      input.checked = false;
    });
  }

  checkCrInput(nodes, queryKey) {
    const queries = Util.getUrlQueryAsHash();
    Array.prototype.slice.call(nodes, 0).forEach((input) => {
      if (input.value === queries[queryKey]) input.checked = true;
    });
  }

  updateFormView() {
    this.checkCrInput(this.areaInputs, 'lrgCd');
    this.checkCrInput(this.catInputs, 'category');
    this.checkCrInput(this.commitInputs, 'kdCond');
  }

  render() {
    this.updateFormView();
  }
}
