export default class Util {
  static getUrlQueryAsHash() {
    const queryStr = window.location.search.slice(1); // 文頭?除外
    let queries = {};

    // クエリがない場合は空のオブジェクト返却
    if (!queryStr) return queries;

    queryStr.split('&').forEach(function (queryStr) {
      const queryArr = queryStr.split('=');
      queries[queryArr[0]] = queryArr[1];
    });

    return queries;
  }

  static getQueryStrFromHash(hash) {
    let s = '';
    for (let key in hash) {
      s += `${key}=${hash[key]}&`;
    }
    return s.slice(0, -1);
  }
}
