import { Controller } from '@hotwired/stimulus';

// Jorudan VisitのTrip Blender連携が有効な場合にのみエクスポートページに表示されるボタン押下時に、
// エクスポートプランをTrip Blenderのモデルコースとして公開するための情報を親フレームに送信する。

export default class extends Controller {
  static targets = ['courseInfo'];

  click() {
    this.postCourseInfoMessageToJorudan();
  }

  postCourseInfoMessageToJorudan() {
    // eslint-disable-next-line no-undef
    const receiverOrigin = process.env.JORUDAN_POST_MESSAGE_HOST || '*';
    window.parent.postMessage(
      {
        action: 'PostCourse',
        message: this.courseInfo,
      },
      receiverOrigin
    );
  }

  get courseInfo() {
    return this.courseInfoTarget.value;
  }
}
