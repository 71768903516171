// Tab
//
// Version: v 0.1.0
// Written: Masaaki Sumimoto

class Tab {
  constructor({ tablist }) {
    this.tablist = tablist;
    this.groupId = this.tablist.dataset.groupId;
    this.tabs = this.tablist.querySelectorAll('[data-role="tab"]');
    this.initialActiveTabNavItem = Array.from(this.tabs).find((item) => item.dataset.state === 'active');
    this.tabpanelsWrap = document.querySelector(`[data-role="tab-contents"][data-group-id="${this.groupId}"]`);
    this.tabpanels = this.tabpanelsWrap.querySelectorAll('[data-role="tabpanel"]');

    // init
    //
    const initialContentId = this.initialActiveTabNavItem.dataset.contentId;
    this.updateTabContents(initialContentId);

    // event
    //
    this.tabs.forEach((tabNavItem) => {
      tabNavItem.addEventListener('click', (e) => {
        e.preventDefault();
        this.render(tabNavItem);
        return false;
      });
    });
  }

  updateTabNavItem(id) {
    this.tabs.forEach((item) => {
      const tabItem = item;

      if (id === tabItem.dataset.contentId) {
        tabItem.dataset.state = 'active';
        tabItem.ariaSelected = true;
      }

      if (id !== tabItem.dataset.contentId) {
        delete tabItem.dataset.state;
        tabItem.ariaSelected = false;
      }
    });
  }

  updateTabContents(id) {
    this.tabpanels.forEach((item) => {
      const contentItem = item;
      if (id === contentItem.dataset.contentId) contentItem.dataset.state = 'active';
      if (id !== contentItem.dataset.contentId) delete contentItem.dataset.state;
    });
  }

  render(tabNavItem) {
    const id = tabNavItem.dataset.contentId;
    this.updateTabNavItem(id);
    this.updateTabContents(id);
  }
}

export default Tab;
