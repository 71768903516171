// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  if ($('#jc_user_token').length) {
    // jc_user_tokenをローカルストレージに保存する
    const div = document.getElementById("jc_user_token");
    localStorage.setItem("japan-concierge-user-token", div.dataset.token);
  }
});
