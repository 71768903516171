import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['message'];

  message(event) {
    const [message] = event.detail;

    this.messageTarget.innerHTML = message;
  }
}
