import { Controller } from '@hotwired/stimulus';
import { requestFavoriteSpots, sendFavoriteSpotUpdated, RECEIVE_EVENT } from './favorite_count_controller';

export default class extends Controller {
  static targets = ['add', 'remove'];

  connect() {
    document.addEventListener(RECEIVE_EVENT, this.setState, false);
    requestFavoriteSpots();
  }

  disconnect() {
    document.removeEventListener(RECEIVE_EVENT, this.setState, false);
  }

  setState = ({ detail: ids }) => {
    if (ids.includes(this.id)) {
      this.addTarget.classList.add('d-none');
      this.removeTarget.classList.remove('d-none');
    } else {
      this.addTarget.classList.remove('d-none');
      this.removeTarget.classList.add('d-none');
    }
  };

  added() {
    this.addTarget.classList.add('d-none');
    this.removeTarget.classList.remove('d-none');
    sendFavoriteSpotUpdated();
  }

  removed() {
    this.addTarget.classList.remove('d-none');
    this.removeTarget.classList.add('d-none');
    sendFavoriteSpotUpdated();
  }

  get id() {
    return parseInt(this.data.get('id'), 10);
  }
}
