import { SelectBtn } from './SelectBtn';
import { SelectInput } from './SelectInput';

export class SelectBundler {
  constructor({ node, store, type }) {
    this.node = node;
    this.store = store;

    // SelectBtnとSelectInputの生成
    // 本クラスの関数で操作が必要なため、自身のメンバとして定義しておく

    this.btns = [];
    node
      .querySelectorAll(this.store.config.selectorName.btn)
      .forEach((node) => {
        this.btns.push(
          new SelectBtn({
            node,
            store: this.store,
            type,
            inactiveSelectNodes: this.inactiveSelectNodes.bind(this),
            resetInputNodes: this.resetInputNodes.bind(this),
          })
        );
      });

    this.input = new SelectInput({
      node: node.querySelector(this.store.config.selectorName.input),
      store: this.store,
      type,
      inactiveSelectNodes: this.inactiveSelectNodes.bind(this),
    });

    // Other

    this.btnsActiveClassName = this.store.config.className.activeSelectBtn;
    this.inputActiveClassName = this.store.config.className.activeSelectInput;
  }

  // 以下2つの関数はインスタンスをまたいでデザイン更新が必要な際に子コンポーネントから呼び出される関数

  inactiveSelectNodes() {
    this.btns.forEach((btn) => {
      btn.node.classList.remove(this.btnsActiveClassName);
    });
    this.input.node.classList.remove(this.inputActiveClassName);
  }

  resetInputNodes() {
    this.input.node.value = '';
  }
}
