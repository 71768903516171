export default class Item {
  constructor(args = {}) {
    this.store = args.store;
    this.info = args.tagInfo;
  }

  render() {
    let description;
    if (this.info.description) {
      description = `<p class="small line-clamp-2 mb-2">${this.info.description}</p>`;
    } else {
      description = '';
    }
    return `
      <div class="col-12 col-md-6 col-lg-4 mb-4 mb-md-6">
        <a class="card" href="${this.info.permalink}" target="_blank">
          <div class="card-img ratio ratio-4x3">
            <img class="object-fit-cover-center" src="${this.info.picPath || this.store.view.noImagePath}" />
          </div>
          <div class="card-body">
            <p class="card-title">
              <a class="line-clamp-2 fw-bold text-dark text-decoration-none" href="${
                this.info.permalink
              }" target="_blank">
                ${this.info.name}
              </a>
            </p>
            ${description}
            <div class="small mb-2">
              <span class="text-tertiary">
                <i class="fa-solid fa-map-pin"></i>
              </span>
              ${this.info.address}
            </div>
            <div class="small mb-4">
              評価：${this.info.rating || '---'}
            </div>
          </div>
        </a>
      </div>
    `;
  }
}
