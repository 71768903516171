class CheckboxGroup {
  constructor({ parentElm, app }) {
    this.parentElm = parentElm;
    this.app = app;
    this.groupId = this.parentElm.dataset.groupId;
    this.childElms = document.querySelectorAll(
      `[data-group-id="${this.groupId}"]:not(${this.app.config.parentCkSelectorName})`
    );

    this.parentElm.addEventListener('click', () => {
      // 自身が引き起こしたイベントに対応したviewのみの更新
      this.updateChildrenView();
    });

    this.childElms.forEach((elm) => {
      // 自身が引き起こしたイベントに対応したviewのみの更新
      elm.addEventListener('click', () => {
        this.updateParentView();
      });
    });
  }

  activeParent() {
    this.parentElm.checked = true;
  }

  inactiveParent() {
    this.parentElm.checked = false;
  }

  activeChildren() {
    this.childElms.forEach((elm) => {
      elm.checked = true;
    });
  }

  inactiveChildren() {
    this.childElms.forEach((elm) => {
      elm.checked = false;
    });
  }

  updateChildrenView() {
    if (this.parentElm.checked) this.activeChildren();
    if (!this.parentElm.checked) this.inactiveChildren();
  }

  updateParentView() {
    const hasUncheckedChildMember = () => Array.from(this.childElms).find((elm) => elm.checked === false);
    if (hasUncheckedChildMember()) this.inactiveParent();
    if (!hasUncheckedChildMember()) this.activeParent();
  }
}

export default CheckboxGroup;
