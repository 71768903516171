import $ from 'jquery';

$(() => {
  if ($('.download_target').length) {
    // ダウンロード対象のチェックがONの場合
    $('.download_target').on('click', (e) => {
      const photoId = e.target.dataset.id;

      if ($(`#download_target_${photoId}`).prop('checked')) {
        // 新規登録;
        $.ajax({
          url: `/photo_dl_selections`,
          beforeSend(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          type: 'POST',
          dataType: 'json',
          data: { photo_id: photoId },
          timeout: 3000,
        }).fail(function fail() {
          $(`#download_target_${photoId}`).prop('checked', false);
        });
      } else {
        $.ajax({
          url: `/photo_dl_selections/${photoId}`,
          beforeSend(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          type: 'DELETE',
          dataType: 'json',
          timeout: 3000,
        }).fail(function fail() {
          $(`#download_target_${photoId}`).prop('checked', false);
        });
      }
    });
  }
});
