import { Controller } from '@hotwired/stimulus';
import { setToken } from './iframe_token';

export default class extends Controller {
  connect() {
    window.addEventListener('message', this.tokenEvent, false);
  }

  disconnect() {
    window.removeEventListener('message', this.tokenEvent, false);
  }

  tokenEvent = (event) => {
    if (event.data.type === 'userToken') {
      setToken(event.data.token);
      window.Turbolinks.clearCache();
      window.Turbolinks.visit(this.loadUrl, { action: 'replace' });
    }
  };

  get loadUrl() {
    return this.data.get('url');
  }
}
