export default class PrefBundlerBtn {
  constructor({ app, node }) {
    this.app = app;
    this.node = node;
    this.store = app.store;
    this.id = this.node.dataset.id;
    this.params = {
      pref1: Number(this.node.dataset.pref1),
      pref2: Number(this.node.dataset.pref2),
      pref3: Number(this.node.dataset.pref3),
      pref4: Number(this.node.dataset.pref4),
      pref5: Number(this.node.dataset.pref5),
    };

    this.activeClassName = this.store.config.designActiveClassName;

    this.node.addEventListener('click', () => {
      this.handleClick();
    });
  }

  handleClick() {
    this.app.setState('activePrefBundlerBtnId', this.id);
    this.setPrefState();
  }

  setPrefState() {
    this.app.setState('pref', this.params);
  }

  active() {
    this.node.classList.add(this.activeClassName);
  }

  inactive() {
    this.node.classList.remove(this.activeClassName);
  }

  render() {
    if (this.app.getState('activePrefBundlerBtnId') === this.id) {
      this.active();
    } else {
      this.inactive();
    }
  }
}
