// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="spot_area_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[areas_id_in][]"]:checked').length === $('input[name="q[areas_id_in][]"]').length - 1) {
      $('input[name="spot_area_id[all]"]').prop('checked', true);
    }
  }

  // 各カテゴリーのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchCategoryAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="spot_category_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if (
      $('input[name="q[categories_id_in][]"]:checked').length ===
      $('input[name="q[categories_id_in][]"]').length - 1
    ) {
      $('input[name="spot_category_id[all]"]').prop('checked', true);
    }
  }

  // 現在地取得成功時のみサーバに処理を送信する
  function successGetPosition(position) {
    const url = $('#sort_distance').attr('href');
    $('#sort_distance').attr('href', `${url}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`);
    window.location.href = `${url}&lat=${position.coords.latitude}&lng=${position.coords.longitude}`;
  }

  function failGetPosition(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert('位置情報の取得が許可されませんでした');
        break;
      case error.POSITION_UNAVAILABLE:
        alert('電波状況などで位置情報が取得できませんでした');
        break;
      case error.TIMEOUT:
        alert('位置情報の取得にてタイムアウトが発生しました');
        break;
      case error.UNKNOWN_ERROR:
        alert('原因不明のエラーが発生しました');
        break;
    }
  }

  // 現在地に近いボタン押下時、現在地を取得する
  if ($('#sort_distance').length) {
    $('#sort_distance').click(function f() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successGetPosition, failGetPosition, options);
      } else {
        alert('ご使用中のブラウザは現在地検索に対応されておりません');
      }
      return false;
    });
  }

  if ($('#spot_search').length) {
    // エリア全て選択時、エリアの状態も変更する
    $('input[name="spot_area_id[all]"]').click((e) => {
      $('input[name="q[areas_id_in][]"]').prop('checked', e.target.checked);
    });

    // エリア選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[areas_id_in][]"]').click(() => {
      switchAreaAllCheckbox();
    });

    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="spot_category_id[all]"]').click((e) => {
      $('input[name="q[categories_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[categories_id_in][]"]').click(() => {
      switchCategoryAllCheckbox();
    });
  }
});

// 詳細ページの画像スライダ
let singlePageItemSwiper = document.querySelector('[data-role="single-page-mv-swiper"]');
if (singlePageItemSwiper !== null) {
  // slideの個数を取得
  let swiperItems = singlePageItemSwiper.querySelectorAll('.swiper-slide').length;

  // swiperの指定
  let singlePageMvSwiper;
  let singlePageMvThumbSwiper;

  // swiper設定
  const createThumbSwiper = function (loopThumb) {
    let thumbParam = {
      slidesPerView: 2.4,
      spaceBetween: 16,
      loop: true,
      speed: 1500,
      allowTouchMove: true,
      breakpoints: {
        768: {
          slidesPerView: 5,
          spaceBetween: 32,
        },
      },
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      watchOverflow: true,
    };
    // loopしない場合は下記を書き換え
    if (!loopThumb) {
      thumbParam.loop = false;
      thumbParam.allowTouchMove = false;
    }
    singlePageMvThumbSwiper = new Swiper('[data-role="single-page-mv-thumb-swiper"]', thumbParam);
  };
  const createSwiper = function (loop) {
    let param = {
      slidesPerView: 1.2,
      spaceBetween: 16,
      centeredSlides: true,
      loop: true,
      allowTouchMove: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      navigation: {
        nextEl: '[data-role="single-page-mv-swiper-next"]',
        prevEl: '[data-role="single-page-mv-swiper-prev"]',
      },
      breakpoints: {
        768: {
          slidesPerView: 1.5,
          spaceBetween: 32,
        },
      },
      thumbs: {
        swiper: singlePageMvThumbSwiper,
      },
    };
    // loopしない場合は下記を書き換え
    if (!loop) {
      param.loop = false;
      param.allowTouchMove = false;
    }
    singlePageMvSwiper = new Swiper('[data-role="single-page-mv-swiper"]', param);
  };

  // 読み込まれた時
  function slideWidthWatch(load) {
    if (!load) {
      singlePageMvThumbSwiper.destroy(false, true);
      singlePageMvSwiper.destroy(false, true);
    }
    if (swiperItems === 2) {
      createThumbSwiper(false);
      createSwiper(true);
    } else if (swiperItems >= 3 && swiperItems <= 5) {
      if (768 < window.innerWidth) {
        createThumbSwiper(false);
        createSwiper(true);
      } else {
        createThumbSwiper(true);
        createSwiper(true);
      }
    } else {
      createThumbSwiper(true);
      createSwiper(true);
    }
  }

  // スライドの個数が5枚以下の時はリサイズイベント
  if (swiperItems <= 5) {
    window.addEventListener('resize', function () {
      slideWidthWatch(false);
    });
  }

  slideWidthWatch(true);
}

// 詳細ページ音声データ
if (document.querySelector('.single-page-audio') !== null) {
  const audioWrap = document.querySelector('.single-page-audio');
  const audio = audioWrap.querySelector('audio');
  const playBtn = audioWrap.querySelector('.js-sound-play');
  const stopBtn = audioWrap.querySelector('.js-sound-stop');
  playBtn.addEventListener('click', () => {
    audio.play();
    playBtn.classList.add('is-hidden');
    stopBtn.classList.remove('is-hidden');
  });
  stopBtn.addEventListener('click', () => {
    audio.pause();
    audio.currentTime = 0;
    playBtn.classList.remove('is-hidden');
    stopBtn.classList.add('is-hidden');
  });
  audio.addEventListener(
    'ended',
    () => {
      audio.currentTime = 0;
      playBtn.classList.remove('is-hidden');
      stopBtn.classList.add('is-hidden');
    },
    { once: true }
  );
}
