class Selector {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
    this.contentId = this.elm.dataset.contentId;

    this.elm.addEventListener('click', (e) => {
      e.preventDefault();

      const isActiveSelector = () => this.contentId === this.app.state.activeContentId;

      if (isActiveSelector()) {
        this.app.state.isContentActive = false;
        this.app.state.activeContentId = null;
      } else {
        this.app.state.isContentActive = true;
        this.app.state.activeContentId = this.contentId;
      }

      this.app.render();

      return false;
    });
  }

  render() {
    const isActiveSelector = () => this.contentId === this.app.state.activeContentId;

    if (isActiveSelector()) {
      this.elm.dataset.state = 'active';
    } else {
      delete this.elm.dataset.state;
    }
  }
}

export default Selector;
