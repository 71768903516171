export default class Loading {
  constructor(args = {}) {
    this.store = args.store;
    this.node = document.querySelector('[data-role="jalan-loading"]');
  }

  show() {
    this.node.classList.remove(this.store.view.hideClassName);
  }

  hide() {
    setTimeout(() => {
      this.node.classList.add(this.store.view.hideClassName);
    }, 200);
  }
}
