class AreaImagemapArea {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
    this.id = this.elm.dataset.areaId;

    this.elm.addEventListener('click', (e) => {
      e.preventDefault();

      if (this.elm.dataset.state === 'active')
        this.app.state.activeAreaIds = this.app.state.activeAreaIds.filter((id) => id !== this.id);
      if (this.elm.dataset.state !== 'active') this.app.state.activeAreaIds.push(this.id);

      this.app.render();

      return false;
    });
  }

  render() {
    const isActiveArea = () => this.app.state.activeAreaIds.find((id) => this.id === id);

    if (isActiveArea()) this.elm.dataset.state = 'active';
    if (!isActiveArea()) delete this.elm.dataset.state;
  }
}

export default AreaImagemapArea;
