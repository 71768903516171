// SwiperSingleFade
//

import Swiper from 'swiper/bundle';

class SwiperSingleFade {
  constructor({ elm }) {
    this.swiper = new Swiper(elm, {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      spaceBetween: 0,
      loop: true,
      effect: 'fade',
      crossFade: true,
      speed: 1000,
      pagination: {
        el: elm.querySelector("[data-role='single-fade-swiper-pagination']"),
        clickable: true,
      },
      navigation: {
        nextEl: elm.querySelector("[data-role='single-fade-swiper-next']"),
        prevEl: elm.querySelector("[data-role='single-fade-swiper-prev']"),
      },
    });
  }
}

export default SwiperSingleFade;
