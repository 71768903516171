import Swiper from 'swiper/bundle';

// swiper
const majorSpotAlbumSwiperBuild = function () {
  let majorSpotAlbumSwiperElm = document.querySelector('[data-role="major-spot-album-swiper"]');
  if (majorSpotAlbumSwiperElm !== null) {
    let majorSpotAlbumSwiper;
    let majorSpotAlbumSwiperParam = {
      slidesPerView: 1.4,
      spaceBetween: 16,
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 1500,
      breakpoints: {
        768: {
          slidesPerView: 2.2,
          spaceBetween: 32,
        },
      },
      pagination: {
        el: "[data-role='major-spot-album-swiper-pagination']",
        clickable: true,
      },
      navigation: {
        nextEl: "[data-role='major-spot-album-swiper-next']",
        prevEl: "[data-role='major-spot-album-swiper-prev']",
      },
    };
    majorSpotAlbumSwiper = new Swiper('[data-role="major-spot-album-swiper"]', majorSpotAlbumSwiperParam);
  }
};
majorSpotAlbumSwiperBuild();

// 各月の要素を監視、中身が変わればswiperを発火
const majorSpotAlbum = document.querySelector('.major-spot-album-wrap');
if (majorSpotAlbum !== null) {
  const observer = new MutationObserver(function () {
    majorSpotAlbumSwiperBuild();
  });
  const config = {
    attributes: true,
    childList: true,
    characterData: true,
  };
  observer.observe(majorSpotAlbum, config);
}

// 年選択
if (document.querySelector('[data-role="album-years-list"]') !== null) {
  const yearItems = document.querySelectorAll('[data-role="album-years-list-item"]');
  const monthsLists = document.querySelectorAll('[data-role="album-months-list"]');
  const monthItems = document.querySelectorAll('[data-role="album-months-list-item"]');
  Array.from(yearItems).forEach((currentYear) => {
    currentYear.addEventListener('click', () => {
      const targetYear = currentYear.dataset.year;
      yearItems.forEach((yearItem) => {
        yearItem.classList.remove('is-active');
      });
      currentYear.classList.add('is-active');
      monthsLists.forEach((monthsList) => {
        monthsList.classList.remove('is-active');
        const yearData = monthsList.dataset.year;
        if (targetYear == yearData) {
          monthsList.classList.add('is-active');
        }
      });
    });
  });
  Array.from(monthItems).forEach((currentMonth) => {
    currentMonth.addEventListener('click', () => {
      monthItems.forEach((monthItem) => {
        monthItem.classList.remove('is-active');
      });
      currentMonth.classList.add('is-active');
    });
  });
}
