import Util from './Util';

export default class SortButtons {
  constructor(args = {}) {
    this.store = args.store;
    this.nodes = document.querySelectorAll('[data-role="jalan-sort-btn"]');
    Array.prototype.slice.call(this.nodes, 0).forEach((sortBtn) => {
      sortBtn.addEventListener('click', (e) => {
        location.href = `${location.origin}${
          location.pathname
        }?${this.getQueryWithNewSortParam(e)}`;
      });
    });
  }

  getQueryWithNewSortParam(event) {
    const queryHash = Util.getUrlQueryAsHash();
    queryHash.activeSort = event.target.dataset.activesort;
    // 併せてページャーのリセット
    if (queryHash.displayFrom) queryHash.displayFrom = 1;
    return Util.getQueryStrFromHash(queryHash);
  }

  render() {
    Array.prototype.slice.call(this.nodes, 0).forEach((btn) => {
      btn.classList.remove(this.store.view.sortButtonActiveClassName);
    });
    Array.prototype.slice.call(this.nodes, 0).forEach((btn) => {
      if (
        Number(btn.dataset.activesort) ===
        Number(this.store.requestQuery.activeSort)
      ) {
        btn.classList.add(this.store.view.sortButtonActiveClassName);
      }
    });
  }
}
