import Form from './formUi/Form';
import TasteBundlerBtn from './formUi/TasteBundlerBtn';
import PrefBundlerBtn from './formUi/PrefBundlerBtn';
import PrefSlider from './formUi/PrefSlider';
import TasteSlider from './formUi/TasteSlider';
import Select from './formUi/Select';
import SubmitBtn from './formUi/SubmitBtn';
// import Loader from './assistUi/Loader';

export default class App {
  constructor({
    aicAPIBaseURI,
    apiKey,
    place,
    activeTasteBundlerBtnId = 'taste-bundler-btn-1',
    activePrefBundlerBtnId = 'pref-bundler-btn-1',
    designActiveClassName = 'is-selected',
  }) {
    // Store
    // - 全てのデザインコンポーネントが共有する情報の集合
    // - stateはコンストラクタ内、最後に定義

    this.store = {
      config: {
        aicAPIBaseURI,
        apiKey,
        place,
        designActiveClassName,
        selector: {
          form: '[data-mp-role="aic-form"]',
          tasteBundlerBtn: '[data-mp-role="aic-taste-bundler-btn"]',
          prefBundlerBtn: '[data-mp-role="aic-pref-bundler-btn"]',
          prefSlider: '[data-mp-role="aic-pref-slider"]',
          tasteSlider: '[data-mp-role="aic-taste-slider"]',
          startSpotSelect: '[data-mp-role="aic-start-spot-select"]',
          durationSelect: '[data-mp-role="aic-duration-select"]',
          startTimeSelect: '[data-mp-role="aic-start-time-select"]',
          daysSelect: '[data-mp-role="aic-days-select"]',
          transportationSelect: '[data-mp-role="aic-transportation-select"]',
          submitBtn: '[data-mp-role="aic-submit-btn"]',
          // loader: '[data-mp-role="aic-loader"]',
        },
        selectTypes: ['startSpot', 'duration', 'startTime', 'days', 'transportation'],
      },

      state: {
        _activeTasteBundlerBtnId: undefined,
        _activePrefBundlerBtnId: undefined,
        _taste: {
          taste1: undefined,
          taste2: undefined,
          taste3: undefined,
          taste4: undefined,
        },
        _pref: {
          pref1: undefined,
          pref2: undefined,
          pref3: undefined,
          pref4: undefined,
          pref5: undefined,
        },
        _startSpot: undefined,
        _duration: undefined,
        _startTime: undefined,
        _days: undefined,
        _transportation: undefined,
        _aicAPIInfo: undefined,
        _wpAPIInfo: undefined,
        _mixInfo: undefined,
        _hasValidSpotData: undefined,
        _firstSubmit: false, // 一度でも検索を行ったかを取得可能
        _isShownForm: true,
      },
    };

    // Design Components
    // - DOMにマッピングされたインスタンス群

    // Node of Form UI

    this.form = new Form({
      app: this,
      node: document.querySelector(this.store.config.selector.form),
    });

    this.tasteBundlerBtns = [];
    document.querySelectorAll(this.store.config.selector.tasteBundlerBtn).forEach((node) => {
      this.tasteBundlerBtns.push(new TasteBundlerBtn({ app: this, node }));
    });

    this.prefBundlerBtns = [];
    document.querySelectorAll(this.store.config.selector.prefBundlerBtn).forEach((node) => {
      this.prefBundlerBtns.push(new PrefBundlerBtn({ app: this, node }));
    });

    this.prefSliders = [];
    document.querySelectorAll(this.store.config.selector.prefSlider).forEach((node) => {
      this.prefSliders.push(new PrefSlider({ app: this, node }));
    });

    this.tasteSlider = new TasteSlider({
      app: this,
      node: document.querySelector(this.store.config.selector.tasteSlider),
    });

    this.selects = [];
    this.store.config.selectTypes.forEach((select) => {
      this.selects.push(
        new Select({
          app: this,
          node: document.querySelector(this.store.config.selector[`${select}Select`]),
          type: select,
        })
      );
    });

    this.submitBtns = [];
    document.querySelectorAll(this.store.config.selector.submitBtn).forEach((node) => {
      this.submitBtns.push(
        new SubmitBtn({
          app: this,
          node,
        })
      );
    });

    // Node of Assist UI

    // [to FE]
    // Loaderのパーツがviewになかったので、一時的にコメントアウト
    // this.loader = new Loader({
    //   app: this,
    //   node: document.querySelector(this.store.config.selector.loader),
    // });

    // Initial State & Render View
    // - 現在undefinedとなっているstateの初回割り当てを行う
    // - これにより各stateに関係したデザインコンポーネントがrenderメソッドにより見た目を更新する

    // A. アプリ実行者側の指定で初期値が決まるstateの初期化
    this.setState('activePrefBundlerBtnId', activePrefBundlerBtnId);
    this.setState('activeTasteBundlerBtnId', activeTasteBundlerBtnId);
    const activeTasteBundlerBtn = this.tasteBundlerBtns.find(
      (btn) => btn.id === this.getState('activeTasteBundlerBtnId')
    );
    this.setState('taste', {
      ...activeTasteBundlerBtn.params,
      ...this.tasteSlider.params,
    });

    const activePrefBundlerBtn = this.prefBundlerBtns.find((btn) => btn.id === this.getState('activePrefBundlerBtnId'));
    this.setState('pref', activePrefBundlerBtn.params);

    // B. 初期htmlパーツの状態を元に初期値が決まるstateの初期化
    this.selects.forEach((select) => {
      this.setState(select.type, select.node.value);
    });
  }

  getState(targetState) {
    return this.store.state[`_${targetState}`];
  }

  setState(targetState, v) {
    this.store.state[`_${targetState}`] = v;

    // Bind Design Component Render

    switch (targetState) {
      case 'activeTasteBundlerBtnId':
        this.tasteBundlerBtns.forEach((btn) => {
          btn.render();
        });
        break;

      case 'activePrefBundlerBtnId':
        this.prefBundlerBtns.forEach((btn) => {
          btn.render();
        });
        break;

      case 'activeTasteRadioId':
        this.tasteRadios.forEach((radio) => {
          radio.render();
        });
        break;

      case 'pref':
        this.prefSliders.forEach((slider) => {
          slider.render();
        });
        break;

      case 'isFetching':
        // this.loader.render(); [to FE] Loadingパーツがないため、一時的にコメントアウト
        // this.spotItems.render();
        // this.courseCopyBtn.render();
        // this.resultMsg.render();
        // this.resultNoItemMsg.render();
        break;

      case 'isShownForm':
        this.reSearchBtn.render();
        this.form.render();
        break;

      default:
        break;
    }

    // Save All Store to HTML Tag
    this.form.node.dataset.state = JSON.stringify(this.store.state);
    // console.log(JSON.parse(this.form.node.dataset.state)); // 取得方法

    // [Debug] stateが更新監視・出力
    // console.log(`[debug] ${targetState}情報が更新されました`, this.store.state);
  }

  getQueries() {
    const taste = this.getState('taste');
    const pref = this.getState('pref');
    let params = '';

    params += `apikey=${this.store.config.apiKey}`;
    params += `&place=${this.store.config.place}`;
    params += `&start_spot_id=${this.getState('startSpot')}`;
    params += `&duration=${this.getState('duration')}`;
    params += `&start_time=${this.getState('startTime')}`;
    params += `&days=${this.getState('days')}`;
    params += `&transportation=${this.getState('transportation')}`;
    params += `&taste=${taste.taste1},${taste.taste2},${taste.taste3},${taste.taste4}`;
    params += `&pref=${pref.pref1},${pref.pref2},${pref.pref3},${pref.pref4},${pref.pref5}`;

    return params;
  }

  aiRoutePlannerSubmitBtnScroller = {
    targetElmIdName: 'aic_search_result',
    yOffset: -200,
    animInterval: 15,
    stepCount: 1,
    stepNum: 20,
    scroll() {
      document.documentElement.scrollTop = 0;
      const targetElmYloc = document.getElementById(this.targetElmIdName).getBoundingClientRect().top;
      // let { stepCount } = this;
      // const { stepNum } = this;
      const distance = targetElmYloc + this.yOffset;
      window.scrollBy(0, distance);
      // const scrollNode = setInterval(() => {
      //   if (stepCount <= stepNum) {
      //     window.scrollBy(0, distance / stepNum);
      //     stepCount += 1;
      //   } else {
      //     clearInterval(scrollNode);
      //   }
      // }, this.animInterval);
    },
  };

  // [todo] ハードコードです。
  // [note] rails版では利用しないと思われるためコメントアウト
  // scrollAppTopLoc() {
  //   const rect = document.querySelector('#conciergeMenuHead').getBoundingClientRect();
  //   document.documentElement.scrollTop = rect.top + window.pageYOffset;
  // }
}
