import { Controller } from '@hotwired/stimulus';
import { Sortable } from 'sortablejs';
import { sortToApi } from '../../japan_concierge/slices/planSpotSlice';
import { deleteTransit } from '../../japan_concierge/slices/transitSlice';

export default class extends Controller {
  connect() {
    Sortable.create(this.element, {
      handle: '.sort-handle',
      onEnd: ({ newIndex, oldIndex }) => {
        if (newIndex !== oldIndex) {
          // react+reduxへ処理を委託
          const planSpotIds = this.application.planStore.getState().planSpot.ids;
          const planSpotEntities = this.application.planStore.getState().planSpot.entities;
          const swapSourcePlanSpot = planSpotEntities[planSpotIds[oldIndex]];
          const swapTargetPlanSpot = planSpotEntities[planSpotIds[newIndex]];

          // planSpotが更新された場合、再レンダリングが走り、非同期で移動情報取得が行われるため正しいデータを削除してしまう可能性がある
          // 移動したplanSpotの移動情報を削除し、再取得する
          this.application.planStore.dispatch(deleteTransit(swapSourcePlanSpot.id));
          this.application.planStore.dispatch(deleteTransit(swapTargetPlanSpot.id));

          this.application.planStore.dispatch(sortToApi({ id: swapSourcePlanSpot.id, position: newIndex }));
        }
      },
    });
  }
}
