import Swiper from 'swiper/bundle';

const charmMvSwiperElms = document.querySelectorAll("[data-role='charm-mv-swiper']");
if (charmMvSwiperElms.length) {
  charmMvSwiperElms.forEach((elm) => {
    let charmMvSwiper = new Swiper(elm, {
      loop: true,
      slidesPerView: 1.5,
      speed: 8000,
      allowTouchMove: false,
      autoplay: {
        delay: 0,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1012: {
          slidesPerView: 4,
        },
      },
    });
  });
}
