export default class Select {
  constructor({ app, node, type }) {
    this.app = app;
    this.node = node;
    this.store = app.store;
    this.type = type;

    this.node.addEventListener('change', () => {
      this.handleChange();
    });
  }

  handleChange() {
    this.app.setState(this.type, this.node.value);
  }
}
