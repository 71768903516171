import { getToken } from './iframe_token';

document.addEventListener('ajax:beforeSend', ({ detail: [xhr] }) => {
  const token = getToken();

  if (token) {
    xhr.setRequestHeader('USER-TOKEN', token);
  }
});

document.addEventListener('turbolinks:request-start', ({ data: { xhr } }) => {
  const token = getToken();

  if (token) {
    xhr.setRequestHeader('USER-TOKEN', token);
  }
});
