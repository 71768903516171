export default class TasteSlider {
  constructor({ app, node }) {
    this.app = app;
    this.node = node;
    this.store = app.store;
    this.params = { taste4: Number(this.node.value) };

    this.node.addEventListener('change', () => {
      this.handleChange();
    });
  }

  handleChange() {
    this.params = { taste4: Number(this.node.value) };
    this.setTasteState();
  }

  setTasteState() {
    const newState = { ...this.app.getState('taste'), ...this.params };
    this.app.setState('taste', newState);
  }
}
