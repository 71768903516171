class AreaPartialMapImg {
  constructor({ elm, app }) {
    this.elm = elm;
    this.app = app;
    this.id = this.elm.dataset.areaId;
  }

  render() {
    const isActiveArea = () => this.app.state.activeAreaIds.find((id) => this.id === id);

    if (isActiveArea()) this.elm.dataset.state = 'active';
    if (!isActiveArea()) delete this.elm.dataset.state;
  }
}

export default AreaPartialMapImg;
