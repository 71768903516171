export default class StatusText {
  constructor(args = {}) {
    this.store = args.store;
    this.node = document.querySelector('[data-role="jalan-status"]');
  }

  getItemsStatusMsg() {
    const crPageNum = Math.floor(this.store.fetchData.displayFrom / this.store.itemSizePerPage) + 1;

    return `全${this.store.fetchData.numberOfResults}件のうち、${crPageNum}ページ目を表示しています。`;
  }

  getNoItemMsg() {
    return `該当するスポットがありません。`;
  }

  getInvalidMsg() {
    return `無効です。`;
  }

  getSystemErrorMsg() {
    return `エラーが発生しました。申し訳ございませんが、少し時間をあけてからご利用ください。`;
  }

  render() {
    switch (this.store.jalanStatus) {
      case 'systemError':
        this.node.innerHTML = this.getSystemErrorMsg();
        break;
      case 'invalidRequest':
        this.node.innerHTML = this.getInvalidMsg();
        break;
      case 'noItem':
        this.node.innerHTML = this.getNoItemMsg();
        break;
      case 'hasItem':
        this.node.innerHTML = this.getItemsStatusMsg();
        break;
      default:
        break;
    }
  }
}
