import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitButton'];

  connect() {
    // 自動的にSNSログイン画面へ遷移させる為にformボタンのclickイベントを発火させる
    this.submitButtonTarget.click();
  }
}
