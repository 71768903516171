export default class PrefSlider {
  constructor({ app, node }) {
    this.app = app;
    this.node = node;
    this.store = app.store;
    this.target = this.node.dataset.target;

    this.node.addEventListener('change', () => {
      this.handleChange();
    });
  }

  handleChange() {
    const newState = {};
    const prefState = this.app.getState('pref');
    Object.keys(prefState).forEach((member) => {
      if (Object.hasOwnProperty.call(prefState, member)) {
        if (member === this.target) {
          newState[member] = Number(this.node.value);
        } else {
          newState[member] = prefState[member];
        }
      }
    });
    this.app.setState('pref', newState);
  }

  render() {
    this.node.value = this.app.getState('pref')[this.target];
  }
}
