// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import Swiper from 'swiper/bundle';

if (document.querySelector("[data-role='top-mv-swiper']") !== null) {
  let topMvSwiper = new Swiper("[data-role='top-mv-swiper']", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    navigation: {
      nextEl: "[data-role='top-mv-swiper-next']",
      prevEl: "[data-role='top-mv-swiper-prev']",
    },
  });
}

if (document.querySelector("[data-role='top-thingstodo-swiper']") !== null) {
  let topThingstodoSwiper = new Swiper("[data-role='top-thingstodo-swiper']", {
    slidesPerView: 1.2,
    spaceBetween: 16,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    navigation: {
      nextEl: "[data-role='top-thingstodo-swiper-next']",
      prevEl: "[data-role='top-thingstodo-swiper-prev']",
    },
    breakpoints: {
      768: {
        slidesPerView: 2.06,
        spaceBetween: 24,
        centeredSlides: false,
      },
    },
  });
}

if (document.querySelector("[data-role='top-movie-swiper']") !== null) {
  let topThingstodoSwiper = new Swiper("[data-role='top-movie-swiper']", {
    slidesPerView: 2,
    spaceBetween: 24,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    navigation: {
      nextEl: "[data-role='top-movie-swiper-next']",
      prevEl: "[data-role='top-movie-swiper-prev']",
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      1012: {
        slidesPerView: 4,
      },
    },
  });
}

if (document.querySelector('.top-topics-item-contents__text') !== null) {
  $(window).on('load resize', function () {
    $('.top-topics-item-contents__text').each(function () {
      var $target = $(this);
      var html = $target.html();
      var $clone = $target.clone();
      $clone
        .css({
          display: 'none',
          position: 'absolute',
          overflow: 'visible',
        })
        .width($target.width())
        .height('auto');
      $target.after($clone);
      while (html.length > 0 && $clone.height() > $target.height()) {
        html = html.substr(0, html.length - 1);
        $clone.html(html + '...');
      }
      $target.html($clone.html());
      $clone.remove();
    });
  });
}

if (document.querySelector("[data-role='top-course-swiper']") !== null) {
  let topThingstodoSwiper = new Swiper("[data-role='top-course-swiper']", {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed: 1500,
    navigation: {
      nextEl: "[data-role='top-course-swiper-next']",
      prevEl: "[data-role='top-course-swiper-prev']",
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 24,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        speed: 1500,
      },
      1012: {
        slidesPerView: 4,
        loop: false,
        autoplay: false,
        followFinger: false,
        spaceBetween: 32,
      },
    },
  });
}

// くりはらの四季
if (document.querySelector('.top-seasons') !== null) {
  const seasonBtns = document.querySelectorAll('.top-seasons-tab__btn');
  seasonBtns.forEach((seasonBtn) => {
    seasonBtn.addEventListener('click', () => {
      const parent = seasonBtn.closest('.top-seasons');
      const thisSeasone = seasonBtn.dataset.bsTarget.replace('#', '');
      parent.dataset.season = thisSeasone;
    });
  });
}
