export class SelectBtn {
  constructor({ node, store, type, inactiveSelectNodes, resetInputNodes }) {
    this.node = node;
    this.name = node.dataset.searchName;
    this.activeClassName = store.config.className.activeSelectBtn;

    this.node.addEventListener('click', () => {
      inactiveSelectNodes();
      resetInputNodes();

      this.node.classList.add(this.activeClassName);
      store.state[type] = this.name;
    });
  }
}
