export default class Form {
  constructor({ app, node }) {
    this.app = app;
    this.node = node;
    this.store = app.store;

    this.hideClassName = this.node.dataset.hideClass;
  }

  show() {
    this.node.classList.remove(this.hideClassName);
  }

  hide() {
    this.node.classList.add(this.hideClassName);
  }

  render() {
    this.hide();
    if (this.app.getState('isShownForm')) {
      this.show();
    }
  }
}
