export default class TasteBundlerBtn {
  constructor({ app, node }) {
    this.app = app;
    this.node = node;
    this.store = app.store;
    this.id = this.node.dataset.id;
    this.params = {
      taste1: Number(this.node.dataset.taste1),
      taste2: Number(this.node.dataset.taste2),
      taste3: Number(this.node.dataset.taste3),
    };

    this.activeClassName = this.store.config.designActiveClassName;

    this.node.addEventListener('click', () => {
      this.handleClick();
    });
  }

  handleClick() {
    this.app.setState('activeTasteBundlerBtnId', this.id);
    this.setTasteState();
  }

  setTasteState() {
    const newState = { ...this.app.getState('taste'), ...this.params };
    this.app.setState('taste', newState);
  }

  active() {
    this.node.classList.add(this.activeClassName);
  }

  inactive() {
    this.node.classList.remove(this.activeClassName);
  }

  render() {
    if (this.app.getState('activeTasteBundlerBtnId') === this.id) {
      this.active();
    } else {
      this.inactive();
    }
  }
}
