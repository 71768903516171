import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

const UPDATE_EVENT = 'favorite-spot-updated';
const REQUEST_EVENT = 'favorite-spot-requested';
export const RECEIVE_EVENT = 'all-favorite-spots';

export function sendFavoriteSpotUpdated() {
  const event = new CustomEvent(UPDATE_EVENT);
  document.dispatchEvent(event);
}

export function requestFavoriteSpots() {
  const event = new CustomEvent(REQUEST_EVENT);
  document.dispatchEvent(event);
}

export default class extends Controller {
  connect() {
    this.getAll();
    document.addEventListener(UPDATE_EVENT, this.getAll, false);
    document.addEventListener(REQUEST_EVENT, this.sendAll, false);
  }

  disconnect() {
    document.removeEventListener(UPDATE_EVENT, this.getAll, false);
    document.removeEventListener(REQUEST_EVENT, this.sendAll, false);
  }

  getAll = () => {
    Rails.ajax({
      url: this.path,
      type: 'GET',
      beforeSend: (xhr, options) => {
        // Set user token for iframe case.
        Rails.fire(this.element, 'ajax:beforeSend', [xhr, options]);
        return true;
      },
      success: (ids) => {
        this.ids = ids;
        this.element.innerHTML = ids.length;
        this.sendAll();
      },
    });
  };

  sendAll = () => {
    const event = new CustomEvent(RECEIVE_EVENT, { detail: this.ids || [] });
    document.dispatchEvent(event);
  };

  get path() {
    return this.data.get('path');
  }
}
