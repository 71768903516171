export class Submit {
  constructor({ node, store }) {
    this.node = node;
    this.store = store;

    this.node.addEventListener('click', (e) => {
      e.preventDefault();
      const start = this.store.state.start;
      const end = this.store.state.end;
      const locale = this.store.config.locale;

      if (start && end) {
        const url = `${this.store.config.submitBaseUrl}${start}/${end}?hl=${locale}`;
        window.open(url, '_blank');
      } else {
        alert(this.store.config.msg.validError);
      }

      return false;
    });
  }
}
