export default class Store {
  constructor(args = {}) {
    this.requestBaseURI = args.requestBaseURI;
    this.itemSizePerPage = args.itemSizePerPage;
    this.requestQuery = args.requestQuery;
    this.fetchData = null;
    this.jalanStatus = null;
    // user design option
    this.view = {};
    this.view.noImagePath = args.view.noImagePath;
    this.view.sortButtonActiveClassName = args.view.sortButtonActiveClassName;
    this.view.pagerItemActiveClassName = args.view.pagerItemActiveClassName;
    this.view.hideClassName = args.view.hideClassName;
  }
}
