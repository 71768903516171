// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import controllers from './japan_concierge';
import planConfigureStore from '../japan_concierge/active_plan/store';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.planStore = planConfigureStore;
application.load(definitionsFromContext(context));
application.load(controllers);
