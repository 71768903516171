export class SelectInput {
  constructor({ node, store, type, inactiveSelectNodes }) {
    this.node = node;
    this.store = store;
    this.activeClassName = store.config.className.activeSelectBtn;

    this.node.addEventListener('input', () => {
      inactiveSelectNodes();
      this.node.classList.add(this.activeClassName);
      store.state[type] = this.node.value;
    });
  }
}
