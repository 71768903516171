// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

$(() => {
  // 各エリアのチェックボックスが選択された場合、全てのチェックをOff、
  // または全て手動でチェックされた場合は「全て」のチェックボックをOnにする
  function switchAreaAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="course_area_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[areas_id_in][]"]:checked').length === $('input[name="q[areas_id_in][]"]').length - 1) {
      $('input[name="course_area_id[all]"]').prop('checked', true);
    }
  }

  function switchTagAllCheckbox() {
    // 全てのチェックを外す
    $('input[name="course_tag_id[all]"]').prop('checked', false);
    // 全て手動でチェックされた場合、チェックボックスの「全て」にチェックをつける
    if ($('input[name="q[tags_id_in][]"]:checked').length === $('input[name="q[tags_id_in][]"]').length - 1) {
      $('input[name="course_tag_id[all]"]').prop('checked', true);
    }
  }

  if ($('#course_search').length) {
    // エリア全て選択時、エリアの状態も変更する
    $('input[name="course_area_id[all]"]').click((e) => {
      $('input[name="q[areas_id_in][]"]').prop('checked', e.target.checked);
    });

    // エリア選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[areas_id_in][]"]').click(() => {
      switchAreaAllCheckbox();
    });

    // カテゴリー全て選択時、カテゴリーの状態も変更する
    $('input[name="course_tag_id[all]"]').click((e) => {
      $('input[name="q[tags_id_in][]"]').prop('checked', e.target.checked);
    });

    // カテゴリー選択の場合、全ての選択状態を設定/解除を行う
    $('input[name="q[tags_id_in][]"]').click(() => {
      switchTagAllCheckbox();
    });
  }
});
