import './iframe_auth';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import planConfigureStore from '../../japan_concierge/active_plan/store';
import exportConfigureStore from '../../japan_concierge/export/store';
import { setClientHeight, setIframeTop } from '../../japan_concierge/slices/modalSlice';

// eslint-disable-next-line no-undef
const context = require.context('controllers/japan_concierge', true, /_controller\.js$/);
export default definitionsFromContext(context);

window.iFrameResizer = {
  onMessage(message) {
    if (message.clientHeight) {
      planConfigureStore.dispatch(setClientHeight(message.clientHeight));
      exportConfigureStore.dispatch(setClientHeight(message.clientHeight));
    }
    if (message.iframeRect) {
      planConfigureStore.dispatch(setIframeTop(message.iframeRect));
      exportConfigureStore.dispatch(setIframeTop(message.iframeRect));
    }
  },
};
