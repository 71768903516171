import JalanExperience from './JalanExperience/JalanExperience';

// reservationページのじゃらん体験
if (document.querySelector('[data-role="jalan-form"]') !== null) {
  const jalanExperience = new JalanExperience({
    requestBaseURI: '/jalan_experiences',
    prefId: '040000', // kenCd
    areaId: '041200', // lrgCd
    cityId: '042130000', // cityCd
    itemSizePerPage: 24,
    initPageNum: 1,
    sortType: 1,
    view: {
      noImagePath: document.querySelector('[data-role="jalan-no-img-path"]').src,
      sortButtonActiveClassName: 'is-active',
      hideClassName: 'd-none',
    },
  });
}
