import SwiperCmn from './components/SwiperCmn';
import SwiperSingle from './components/SwiperSingle';
import SwiperSingleFade from './components/SwiperSingleFade';
import SwiperSinglePageRecommend from './components/SwiperSinglePageRecommend';
import Tab from './components/Tab';
import ArchiveFilter from './components/ArchiveFilter/ArchiveFilter';

// ArchiveFilter
//
// アーカイブ一覧ページにおける絞り込みフォーム要素パーツ
if (document.querySelector('[data-role="archive-filter"]')) {
  if (document.querySelector('.archive-filter-area-map')) {
    // eslint-disable-next-line no-new
    new ArchiveFilter();
  } else {
    // eslint-disable-next-line no-new
    new ArchiveFilter({ disableAreaMapMode: true });
  }
}

// Tab
//
// ページに存在するdata-role=tab-navの数ぶん生成

if (
  document.querySelectorAll('[data-role="tablist"]').length &&
  document.querySelectorAll('[data-role="tab-contents"]').length
) {
  document.querySelectorAll('[data-role="tablist"]').forEach((tablist) => {
    // eslint-disable-next-line no-new
    new Tab({ tablist });
  });
}

// header関連
if (document.querySelector('.global-header') !== null) {
  // menu開閉ボタン
  const globalHeader = document.querySelector('.global-header');
  const overlay = document.createElement('div');
  overlay.className = 'overlay';
  globalHeader.appendChild(overlay);
  document.querySelector('.js-menu-toggle').addEventListener('click', function () {
    this.classList.toggle('is-menu-open');
    overlay.classList.toggle('is-active');
  });
  overlay.addEventListener('click', function () {
    document.querySelector('.js-menu-toggle').classList.remove('is-menu-open');
    this.classList.remove('is-active');
  });

  // グロナビ開閉
  const globalMenuOpeners = document.querySelectorAll('.js-global-main-menu-toggle');
  for (let i = 0; i < globalMenuOpeners.length; i++) {
    globalMenuOpeners[i].addEventListener('click', function (e) {
      this.classList.toggle('is-main-menu-open');
    });
  }

  // 言語選択ボタン
  const languageMenuOpener = document.querySelectorAll('.js-language-list-toggle');
  for (let i = 0; i < languageMenuOpener.length; i++) {
    languageMenuOpener[i].addEventListener('click', function (e) {
      this.classList.toggle('is-language-list-open');
    });
  }
  const languageSwitchers = document.querySelectorAll('[data-role="language-switcher"]');
  const bokunElm = document.querySelector('[data-role="jtb-bokun"]');
  if (bokunElm !== null) {
    const bokunCnt = bokunElm.querySelector('.bokunWidget');
    const bokunSrc = bokunCnt.dataset.src;
    languageSwitchers.forEach((languageSwitcher) => {
      const currentLanguage = document.querySelectorAll('[data-role="current-language"]');
      const changeLannguages = languageSwitcher.querySelectorAll('[data-role="change-lannguage"]');
      changeLannguages.forEach((changeLannguage) => {
        changeLannguage.addEventListener('click', () => {
          const changeLannguageTxt = changeLannguage.textContent;
          const activeLocale = changeLannguage.lang;
          const thisParent = changeLannguage.closest('[data-role="language-switcher"]');
          const thisLanguageSwitcher = thisParent.querySelector('.js-language-list-toggle');
          thisLanguageSwitcher.classList.remove('is-language-list-open');
          if (bokunElm !== null) {
            const langParam = '?lang=' + activeLocale;
            bokunCnt.dataset.src = bokunSrc + langParam;
          }
          for (let i = 0; i < currentLanguage.length; i++) {
            currentLanguage[i].textContent = changeLannguageTxt;
          }
        });
      });
    });
  }
}

// Carousel
//
// 汎用 Swiper要素

const cmnSwiperElms = document.querySelectorAll("[data-role='cmn-swiper']");
if (cmnSwiperElms.length) {
  cmnSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperCmn({ elm });
  });
}

const singleSwiperElms = document.querySelectorAll("[data-role='single-swiper']");
if (singleSwiperElms.length) {
  singleSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperSingle({ elm });
  });
}

const singleFadeSwiperElms = document.querySelectorAll("[data-role='single-fade-swiper']");
if (singleFadeSwiperElms.length) {
  singleFadeSwiperElms.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperSingleFade({ elm });
  });
}

const spotRecommendSwiperElm = document.querySelectorAll("[data-role='single-page-recommend-swiper']");
if (spotRecommendSwiperElm.length) {
  spotRecommendSwiperElm.forEach((elm) => {
    // eslint-disable-next-line no-new
    new SwiperSinglePageRecommend({ elm });
  });
}

// スクロールアニメーション
if (document.querySelector('.js-fade-in') !== null) {
  let fadeInTarget = document.querySelectorAll('.js-fade-in');
  let jsFadeIn = () => {
    for (let i = 0; i < fadeInTarget.length; i++) {
      const rect = fadeInTarget[i].getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight;
      if (scroll > offset - windowHeight + 150) {
        fadeInTarget[i].classList.add('is-fade-in');
      }
    }
  };
  window.addEventListener('load', jsFadeIn);
  window.addEventListener('scroll', jsFadeIn);
}

// フッタ
const footerMenuTriggers = document.querySelectorAll('[data-role="menu-trigger"]');
if (footerMenuTriggers.length) {
  for (let i = 0; i < footerMenuTriggers.length; i++) {
    footerMenuTriggers[i].addEventListener('click', function (e) {
      this.classList.toggle('is-menu-open');
    });
  }
}
